import React, { useContext, useMemo } from "react";
import PageLayout from "../../layouts/PageLayout";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TranslatorContext } from "../../context/Translator";
import { AgGridReact } from "ag-grid-react";
import { useQuery } from "@tanstack/react-query";
import api from "../../services/api";
import { getAlertList } from "../../lib/actions";

function AlertScreen() {
  const { t } = useContext(TranslatorContext);

  const { isLoading, error, data } = useQuery({
    queryKey: ["repoData"],
    queryFn: getAlertList,
    // refetchInterval: 3000,
  });

  const IdCellRenderer = ({ node }) => {
    return node.rowIndex + 1;
  };

  const colDefs = useMemo(
    () => [
      {
        headerName: "No",
        cellRenderer: IdCellRenderer,
        flex: 1,
      },
      { field: "masterSymbol", headerName: "Master Symbol", flex: 1 },
      { field: "masterVolume", headerName: "Master Volume", flex: 1 },
      { field: "slaveSymbol", headerName: "Slave Symbol", flex: 1 },
      { field: "slaveVolume", headerName: "Slave Volume", flex: 1 },
      { field: "differenceVolume", headerName: "Difference Volume", flex: 1 },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      // make every column editable
      editable: false,
      selectable: true,
      // make every column use 'text' filter by default
      filter: "agTextColumnFilter",
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      // disable cell data types
      cellDataType: false,
      sortable: true,
    }),
    []
  );

  const rowData = useMemo(() => data?.data?.alerts || [], [data]);
  return (
    <>
      <PageLayout>
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-breadcrumb">
              <h3 className="mc-breadcrumb-title">{t("Alerts")}</h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item">
                  <Link to="/" className="mc-breadcrumb-link">
                    {t("home")}
                  </Link>
                </li>
                <li className="mc-breadcrumb-item">
                  <Link to="/alerts" className="mc-breadcrumb-link">
                    {t("Alerts")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Col>
        <Row>
          <div className="ag-theme-quartz" style={{ height: 500 }}>
            <AgGridReact
              rowData={rowData}
              columnDefs={colDefs}
              defaultColDef={defaultColDef} // Default Column Properties
              animateRows={true} // Optional - set to 'true' to have rows animate when sorted
              overlayNoRowsTemplate={
                isLoading ? "Loading positions..." : "No positions found"
              }
              domLayout="autoHeight"
            />
          </div>
        </Row>
      </PageLayout>
    </>
  );
}

export default AlertScreen;
