import React, { useContext, useState, useEffect, useMemo } from "react";
import PageLayout from "../../layouts/PageLayout";
import { Col, Row } from "react-bootstrap";
import { TranslatorContext } from "../../context/Translator";
import { useQuery } from "@tanstack/react-query";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import { Link } from "react-router-dom";
import { getStateList } from "../../lib/actions";

export default function DashboardScreen() {
  const { t } = useContext(TranslatorContext);

  const { isLoading, error, data } = useQuery({
    queryKey: ["repoData"],
    queryFn: getStateList,
    // refetchInterval: 3000,
  });

  const colDefs = useMemo(
    () => [
      { field: "m_symbol", headerName: "Symbol" },
      { field: "m_net_volume", headerName: "Net Volume" },
      { field: "s_position_symbol", headerName: "LP Symbol " },
      { field: "s_volume", headerName: "LP Net Volume " },
      { field: "difference", headerName: "Difference" },
      { field: "m_position", headerName: "Position" },
      { field: "m_buy_volume", headerName: "Buy Volume" },
      { field: "m_buy_price", headerName: "Buy Price" },
      { field: "m_sell_volume", headerName: "Sell Volume" },
      { field: "m_sell_price", headerName: "Sell Price" },
      { field: "m_profit", headerName: "Profit" },
      { field: "m_uncovered", headerName: "Uncovered" },
      { field: "s_position_ticket", headerName: "LP Ticket" },
      { field: "s_position_openTime", headerName: "LP OpenTime" },
      { field: "s_type", headerName: "LP type" },
      { field: "s_positionPriceOpen", headerName: "LP PriceOpen" },
      { field: "s_positionSL", headerName: "LP SL" },
      { field: "s_positionTP", headerName: "LP TP" },
      { field: "s_position_Swap", headerName: "LP Swap" },
      { field: "s_positionProfit", headerName: "LP Profit" },
      { field: "s_positionLeverage", headerName: "LP Leverage" },
      { field: "s_positionMagic_number", headerName: "LP Magic Number" },
      { field: "s_positionComment", headerName: "LP Comment" },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      // make every column editable
      editable: false,
      selectable: true,
      // make every column use 'text' filter by default
      filter: "agTextColumnFilter",
      // enable floating filters by default
      floatingFilter: true,
      // make columns resizable
      resizable: true,
      // disable cell data types
      cellDataType: false,
      sortable: true,
    }),
    []
  );

  const rowData = useMemo(() => data?.data?.state || [], [data]);

  return (
    <PageLayout>
      <Col xl={12}>
        <div className="mc-card">
          <div className="mc-breadcrumb">
            <h3 className="mc-breadcrumb-title">{t("Dashboard")}</h3>
            <ul className="mc-breadcrumb-list">
              <li className="mc-breadcrumb-item">
                <Link to="/dashboard" className="mc-breadcrumb-link">
                  {t("home")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Col>

      <Row>
        <div className="ag-theme-quartz" style={{ height: 500 }}>
          <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            overlayNoRowsTemplate={
              isLoading ? "Loading positions..." : "No positions found"
            }
            domLayout="autoHeight"
          />
        </div>
      </Row>
    </PageLayout>
  );
}
