import React, { useState, useEffect, useContext } from "react";
import { IconFieldComponent } from "./fields";
import { Button, Spinner } from "react-bootstrap";
import { TranslatorContext } from "../context/Translator";

// Email validation regex pattern
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function CreateEditModalScreen({
  setFormData,
  formData,
  onClick,
  isEditMode,
  isLoading,
  handleClose,
  title,
}) {
  const { t } = useContext(TranslatorContext);
  const [isValid, setIsValid] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const isEmailValid = emailRegex.test(formData?.email || "");
    const isValid = Object?.keys(formData)?.every(
      (key) =>
        key === "id" ||
        (formData[key]?.trim() !== "" && (key !== "email" || isEmailValid))
    );
    setIsValid(isValid);
  }, [formData]);

  const renderFields = () => {
    return Object?.keys(formData)
      .filter((key) => key !== "id") // Exclude 'id' field
      .map((key) => (
        <div key={key}>
          <p>{t(key.charAt(0).toUpperCase() + key.slice(1))}</p>
          <IconFieldComponent
            type="text"
            classes="w-100 h-sm"
            placeholder={t(key)}
            name={key}
            value={formData[key]}
            onChange={handleChange}
          />
        </div>
      ));
  };

  return (
    <>
      <div>
        <div className="py-3">
          <h1 className="text-md">
            {isEditMode ? t(`Update ${title}`) : t(`Create ${title}`)}
          </h1>
        </div>
        <form>
          {renderFields()}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
              justifyContent: "end",
            }}
          >
            <Button variant="secondary" onClick={handleClose}>
              {t("Cancel")}
            </Button>
            <Button onClick={onClick} disabled={!isValid}>
              {isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;{isEditMode ? t("Updating...") : t("Creating...")}
                </>
              ) : isEditMode ? (
                t("Update")
              ) : (
                t("Create")
              )}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateEditModalScreen;
