import React, { useContext, useState } from "react"
import { TranslatorContext } from "../../context/Translator"
import { ButtonComponent, AnchorComponent } from "../../components/elements"
import IconFieldComponent from "../../components/fields/IconFieldComponent"
import LogoComponent from "../../components/LogoComponent"
import { useLogin } from "../../lib/actions"
import { useNavigate } from "react-router-dom"
import getErrorMessage, {
  emitErrorNotification,
  emitNotification,
} from "../../lib/helper"
import { storeToken } from "../../lib/tokenUtils"
import Spinner from "react-bootstrap/Spinner"

export default function LoginPage() {
  const navigate = useNavigate()

  const { t } = useContext(TranslatorContext)

  const [loginData, setLoginData] = useState({
    login: "",
    password: "",
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const { mutate: loginMutation } = useLogin()

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    setError(null)
    loginMutation(loginData, {
      onSuccess: (data) => {
        if (data.status === true && data?.data?.token) {
          storeToken(data?.data?.token)
          emitNotification("success", "Login successful")
          navigate("/dashboard")
        } else {
          emitErrorNotification("Failed to login")
        }
        setLoading(false)
      },
      onError: (error) => {
        emitErrorNotification(getErrorMessage(error))
        setLoading(false)
      },
    })
  }

  return (
    <div className="mc-auth">
      <img
        src="images/pattern.webp"
        alt="pattern"
        className="mc-auth-pattern"
      />
      <div className="mc-auth-group">
        <LogoComponent
          src="images/logo.webp"
          alt="logo"
          href="/ecommerce"
          className="mc-auth-logo"
        />
        <h4 className="mc-auth-title">Login to StrelaSoft</h4>
        <form className="mc-auth-form" onSubmit={handleSubmit}>
          <IconFieldComponent
            icon="email"
            type="text"
            classes="w-100 h-sm"
            placeholder={t("enter your login")}
            value={loginData.login}
            onChange={(e) =>
              setLoginData({ ...loginData, login: e.target.value })
            }
          />
          <IconFieldComponent
            icon="lock"
            type="password"
            classes="w-100 h-sm"
            placeholder={t("enter_your_password")}
            passwordVisible={true}
            value={loginData.password}
            onChange={(e) =>
              setLoginData({ ...loginData, password: e.target.value })
            }
          />
          {/* <IconFieldComponent
            icon="verified_user"
            classes="w-100 h-sm"
            option={[
              "select user role",
              "admin",
              "member",
              "client",
              "manager",
              "vendor",
            ]}
          /> */}

          <div style={{ width: "100%" }}>
            <ButtonComponent
              style={{ width: "100%" }}
              className="mc-auth-btn h-sm disable"
              type="submit"
              disabled={loading}
            >
              {loading ? "Loading..." : t("sign_in")}

              {/* {loading && (
                <Spinner
                  size="sm"
                  style={{ marginLeft: "8px" }}
                  animation="border"
                />
              )} */}
            </ButtonComponent>
          </div>
          {/* <AnchorComponent className="mc-auth-forgot" to="/forgot-password">
            {t("forgot_password")}
          </AnchorComponent> */}
          {/* <div className="mc-auth-divide">
            <span>{t("or")}</span>
          </div>
          <div className="mc-auth-connect">
            <AnchorComponent to="#" className="twitter h-sm">
              <i className="icofont-twitter"></i>
              <span>Continue with Twitter</span>
            </AnchorComponent>
            <AnchorComponent to="#" className="facebook h-sm">
              <i className="icofont-facebook"></i>
              <span>Continue with Facebook</span>
            </AnchorComponent>
          </div> */}
        </form>
        {/* <div className="mc-auth-navigate">
          <span>Don't have an account?</span>
          <AnchorComponent to="/register">{t("register")}</AnchorComponent>
        </div> */}
      </div>
    </div>
  )
}
