import React from "react";
import Modal from "react-bootstrap/Modal";

function CustomModal({ show, handleClose, children }) {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body style={{ minWidth: "500px", width: "100%" }}>
          {children}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CustomModal;
