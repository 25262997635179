import React, { useContext } from "react"
import { TranslatorContext } from "../../../context/Translator"
import { Link } from "react-router-dom"
import { Row, Col, Dropdown, Tab, Tabs } from "react-bootstrap"
import { FloatCardComponent } from "../../../components/cards"
import { PaginationComponent } from "../../../components"
import LabelFieldComponent from "../../../components/fields/LabelFieldComponent"
import InvoiceTableComponent from "../../../components/tables/InvoiceTableComponent"
import PageLayout from "../../../layouts/PageLayout"
import invoices from "../../../assets/data/invoices.json"
import traffics from "../../../assets/data/traffics.json"
import { ButtonComponent } from "../../../components/elements"
import { Button } from "bootstrap"
import AllAccountsTableComponent from "../../../components/tables/AllAccountsTableComponent"

export default function EditUsersListScreen() {
  const { t, c } = useContext(TranslatorContext)

  const floats = [
    {
      title: "recieved_amount",
      digit: 78593,
      icon: "account_balance",
      variant: "lg green",
    },
    {
      title: "drafts_amount",
      digit: 24950,
      icon: "drafts",
      variant: "lg blue",
    },
    {
      title: "pending_amount",
      digit: 53617,
      icon: "pending",
      variant: "lg purple",
    },
  ]

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-breadcrumb">
              <h3 className="mc-breadcrumb-title">{t("Edit User")}</h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item">
                  <Link to="/dashboard" className="mc-breadcrumb-link">
                    {t("home")}
                  </Link>
                </li>
                <li className="mc-breadcrumb-item">
                  <Link to="/users" className="mc-breadcrumb-link">
                    {t("users")}
                  </Link>
                </li>

                <li className="mc-breadcrumb-item">
                  <Link to="/users/1/edit" className="mc-breadcrumb-link">
                    {t("Edit user")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Col>
        {/* {floats.map((float, index) => (
          <Col key={index} md={6} lg={4}>
            <FloatCardComponent
              variant={float.variant}
              digit={c(float.digit)}
              title={t(float.title)}
              icon={float.icon}
            />
          </Col>
        ))} */}
        <Col xl={12}>
          <div className="mc-card">
            <div>
              <Tabs defaultActiveKey="profilea" id="mc" className="mc-tabs">
                <Tab
                  eventKey="profilea"
                  title={t("edit_profile")}
                  className="mc-tabpane profile"
                  style={{ marginRight: "16px" }}
                >
                  <br />
                  <Overview />
                </Tab>
                &emsp;
                <Tab
                  eventKey="accounts"
                  title={t("accounts")}
                  className="mc-tabpane profile"
                >
                  <>
                    <div className="mc-card-header">
                      <h4
                        className="mc-card-title"
                        style={{ marginRight: "auto" }}
                      ></h4>

                      <div>
                        <Link to="/fund-managers/create">
                          <div
                            style={{
                              display: "inline-flex",
                              flexDirection: "row",
                              marginLeft: "auto",
                            }}
                          >
                            <i className="material-icons">add</i> &nbsp; Add
                            Fund Manager
                          </div>
                        </Link>
                        &emsp;{" "}
                        <Link to="/investors/create">
                          <div
                            style={{
                              display: "inline-flex",
                              flexDirection: "row",
                              marginLeft: "auto",
                            }}
                          >
                            <i className="material-icons">add</i> &nbsp; Add
                            Investor
                          </div>
                        </Link>
                      </div>

                      <Dropdown bsPrefix="mc-dropdown">
                        <Dropdown.Toggle bsPrefix="mc-dropdown-toggle"></Dropdown.Toggle>
                        <Dropdown.Menu
                          align="end"
                          className="mc-dropdown-paper"
                        >
                          <button type="button" className="mc-dropdown-menu">
                            <i className="material-icons">edit</i>
                            <span>{t("edit")}</span>
                          </button>
                          <button type="button" className="mc-dropdown-menu">
                            <i className="material-icons">delete</i>
                            <span>{t("delete")}</span>
                          </button>
                          <button type="button" className="mc-dropdown-menu">
                            <i className="material-icons">download</i>
                            <span>{t("download")}</span>
                          </button>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <AllAccounts />
                  </>
                </Tab>
              </Tabs>
            </div>
          </div>
        </Col>
      </Row>
    </PageLayout>
  )
}

const Overview = () => {
  const { t, c } = useContext(TranslatorContext)

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "auto",
        rowGap: "0px",
        width: "100%",
        maxWidth: "600px",
      }}
    >
      <LabelFieldComponent
        type="search"
        label={t("Name")}
        labelDir="label-col"
        fieldSize="mb-4 w-100 h-md"
      />
      <LabelFieldComponent
        type="search"
        label={t("Email")}
        labelDir="label-col"
        fieldSize="mb-4 w-100 h-md"
      />
      <LabelFieldComponent
        type="search"
        label={t("Phone")}
        labelDir="label-col"
        fieldSize="mb-4 w-100 h-md"
      />
      <LabelFieldComponent
        type="search"
        label={t("Country")}
        labelDir="label-col"
        fieldSize="mb-4 w-100 h-md"
      />
      <LabelFieldComponent
        type="file"
        label={t("Profile Picture")}
        labelDir="label-col"
        fieldSize="mb-4 w-100 h-md"
      />

      <ButtonComponent className="mc-btn primary" style={{ height: "32px" }}>
        Create User
      </ButtonComponent>
    </div>
  )
}

const AllAccounts = () => {
  return (
    <>
      <AllAccountsTableComponent
        thead={["login", "leverage", "type", "is active", "created at", "view"]}
        tbody={[
          {
            id: 11849,
            leverage: "400",
            type: "FUNDMANAGER",
            directin: "BOTH",
            name: "Ankit",
            email: "ankit09@gmail.com",
            phone: "+912357654321",
            created_at: "06 Jun 2024, 03:05 PM",
            isActive: true,
          },
          {
            id: 11845,
            leverage: "400",
            type: "FUNDMANAGER",
            directin: "SAME",
            name: "Vimal",
            email: "vimaltets@gmail.com",
            phone: "+918757654321",
            created_at: "06 Jun 2024, 03:05 PM",
            isActive: true,
          },
          {
            id: 11323,
            leverage: "400",
            type: "INVEESTOR",
            directin: "REVERSE",
            name: "Aniket ",
            email: "aniketpubg@gmail.com",
            phone: "+919957654321",
            created_at: "06 Jun 2024, 03:05 PM",
            isActive: false,
          },
          {
            id: 11661,
            leverage: "400",
            type: "FUNDMANAGER",
            directin: "BOTH",
            name: "Shubham ",
            email: "shush@gmail.com",
            phone: "+917657654321",
            created_at: "06 Jun 2024, 03:05 PM",
            isActive: true,
          },
        ]}
      />
    </>
  )
}
