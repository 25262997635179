import React from "react"
import { Link } from "react-router-dom"

export default function TrafficsTableComponent({ thead, tbody }) {
  return (
    <div className="mc-table-responsive">
      <table className="mc-table">
        <thead className="mc-table-head primary">
          <tr>
            {thead.map((item, index) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody className="mc-table-body">
          {tbody.map((item, index) => (
            <tr key={index}>
              <td> # {item.id}</td>

              <td>{item.name}</td>

              <td>{item.email}</td>

              <td>{item.phone}</td>

              <td>{item.created_at}</td>

              <td>
                <Link to={`/users/${item?.id}`}>
                  <i className="material-icons">visibility</i>
                </Link>
              </td>
              {/* <td>
                <div className="mc-table-icon">
                 
                  <p>{item.source.text}</p>
                </div>
              </td>
              <td>{item.users}</td>
              <td>{item.sessions}</td>
              <td>{item.bounce}</td>
              <td>{item.duration}</td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
