import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

function ConfirmModal({
  show,
  handleClose,
  onConfirmClick,
  title = "Are you sure?",
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
  children,
  loading = false,
}) {
  return (
    <Modal show={show}>
      <Modal.Body style={{ minWidth: "500px", width: "100%" }}>
        <div style={{ padding: "16px 0" }}>
          <h2 style={{ textAlign: "center" }}>{title}</h2>
        </div>
        {children && <div style={{ marginBottom: "16px" }}>{children}</div>}
        <div
          style={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Button variant="secondary" onClick={handleClose}>
            {cancelButtonText}
          </Button>
          <Button variant="danger" onClick={onConfirmClick} disabled={loading}>
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;Processing...
              </>
            ) : (
              confirmButtonText
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmModal;
