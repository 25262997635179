import { toast } from "react-toastify";

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export function emitNotification(variant, msg, autoClose = 5000) {
  toast[variant](msg, { containerId: "solid", autoClose });
}

export function emitErrorNotification(
  msg = "Something went wrong, Please try again!"
) {
  toast.error(msg, { containerId: "solid" });
}

export default function getErrorMessage(error) {
  let errorMessage = "Failed to do something exceptional";
  if (error) {
    errorMessage = Array.isArray(error?.response?.data?.message)
      ? error?.response?.data?.message?.[0]
      : error?.response?.data?.message;
  }
  return errorMessage || "Something went wrong";
}
