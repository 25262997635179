import * as React from "react";

// CSS & SCSS
import "./assets/fonts/inter.css";
import "./assets/fonts/material.css";
import "./assets/fonts/icofont/icofont.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/styles.scss";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import "react-toastify/dist/ReactToastify.css";

// JS & COMPONENTS
import "./i18n";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "./context/Themes";
import { SidebarProvider } from "./context/Sidebar";
import { LoaderProvider } from "./context/Preloader";
import { TranslatorProvider } from "./context/Translator";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LoginPage, RegisterPage, ForgotPasswordPage } from "./pages/auth";
import { ToastContainer } from "react-toastify";

import {
  OverviewPage,
  DocumentationPage,
  ChangeLogPage,
  ErrorPage,
} from "./pages/others";
import {
  AvatarPage,
  AlertPage,
  ButtonPage,
  ChartPage,
  TablePage,
  FieldPage,
  HeadingPage,
  ColorPage,
} from "./pages/ui";
import {
  EcommercePage,
  AnalyticsPage,
  CRMPage,
  UserListPage,
  UserProfilePage,
  MyAccountPage,
  ProductListPage,
  ProductViewPage,
  ProductUploadPage,
  InvoiceListPage,
  InvoiceDetailsPage,
  OrderListPage,
  MessagePage,
  NotificationPage,
  BlankPage,
  SettingsPage,
} from "./pages/main";
import DashboardScreen from "./pages/screens/DashboardScreen";
import FundManagerListScreen from "./pages/screens/FundManagerListScreen";
import InvestorsListScreen from "./pages/screens/InvestorsListScreen";
import ReportsListScreen from "./pages/screens/ReportsListScreen";
import CreateUsersListScreen from "./pages/screens/Users/CreateUsersListScreen";
import EditUsersListScreen from "./pages/screens/Users/EditUsersListScreen";
import CreateFundmanager from "./pages/screens/Fundmanager/CreateFundmanager";
import EditFundManagerListScreen from "./pages/screens/Fundmanager/EditFundManagerScreen";
import CreateInvestor from "./pages/screens/Investor/CreateInvestor";
import EditInvestorsListScreen from "./pages/screens/Investor/EditInvestorsScreen";

//Quesry Client
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import SymbolMappingScreen from "./pages/screens/SymbolMappingScreen";
import ProtectedRoute from "./lib/ProtectedRoute";
import EmailScreen from "./pages/screens/EmailScreen";
import AlertScreen from "./pages/screens/AlertScreen";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  // FINAL PATHS LOGIN
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <DashboardScreen />
      </ProtectedRoute>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <DashboardScreen />
      </ProtectedRoute>
    ),
  },
  {
    path: "/symbol-mapping",
    element: (
      <ProtectedRoute>
        <SymbolMappingScreen />
      </ProtectedRoute>
    ),
  },
  {
    path: "/emails",
    element: (
      <ProtectedRoute>
        <EmailScreen />
      </ProtectedRoute>
    ),
  },
  {
    path: "/alerts",
    element: (
      <ProtectedRoute>
        <AlertScreen />
      </ProtectedRoute>
    ),
  },
  { path: "/reports", element: <ReportsListScreen /> },

  //

  //UsersListScreen

  // MAIN PAGES
  { path: "/ecommerce", element: <EcommercePage /> },
  { path: "/analytics", element: <AnalyticsPage /> },
  { path: "/crm", element: <CRMPage /> },
  { path: "/forgot-password", element: <ForgotPasswordPage /> },
  { path: "/register", element: <RegisterPage /> },
  { path: "/login", element: <LoginPage /> },
  { path: "/user-list", element: <UserListPage /> },
  { path: "/user-profile", element: <UserProfilePage /> },
  { path: "/my-account", element: <MyAccountPage /> },
  { path: "/product-list", element: <ProductListPage /> },
  { path: "/product-view", element: <ProductViewPage /> },
  { path: "/product-upload", element: <ProductUploadPage /> },
  { path: "/invoice-list", element: <InvoiceListPage /> },
  { path: "/invoice-details", element: <InvoiceDetailsPage /> },
  { path: "/order-list", element: <OrderListPage /> },
  { path: "/message", element: <MessagePage /> },
  { path: "/notification", element: <NotificationPage /> },
  { path: "/blank-page", element: <BlankPage /> },
  { path: "/settings", element: <SettingsPage /> },

  // UI PAGES
  { path: "/avatars", element: <AvatarPage /> },
  { path: "/alerts", element: <AlertPage /> },
  { path: "/buttons", element: <ButtonPage /> },
  { path: "/charts", element: <ChartPage /> },
  { path: "/tables", element: <TablePage /> },
  { path: "/fields", element: <FieldPage /> },
  { path: "/headings", element: <HeadingPage /> },
  { path: "/colors", element: <ColorPage /> },

  // OTHER PAGES
  { path: "/", element: <OverviewPage /> },
  { path: "/documentation", element: <DocumentationPage /> },
  { path: "/changelog", element: <ChangeLogPage /> },
  { path: "/error", element: <ErrorPage /> },
]);

createRoot(document.getElementById("root")).render(
  <ThemeProvider>
    <LoaderProvider>
      <TranslatorProvider>
        <SidebarProvider>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
            <ToastContainer
              enableMultiContainer
              containerId={"solid"}
              position="bottom-left"
              theme="colored"
              closeButton={false}
              autoClose={1000}
              style={{ minWidth: 340, fontSize: 14 }}
            />
          </QueryClientProvider>
        </SidebarProvider>
      </TranslatorProvider>
    </LoaderProvider>
  </ThemeProvider>
);
