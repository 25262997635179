import React, { useContext, useMemo, useState } from "react";
import { TranslatorContext } from "../../context/Translator";
import { Link } from "react-router-dom";
import { Row, Col, Dropdown } from "react-bootstrap";
import PageLayout from "../../layouts/PageLayout";
import { AgGridReact } from "ag-grid-react";
import { ButtonComponent } from "../../components/elements";

import CustomModal from "../../components/modals/CustomModal";
import {
  useCreateSymbolMapping,
  useDeleteSymbolMapping,
  useSymbolMappingQuery,
  useUpdateSymbolMapping,
} from "../../lib/actions";
import CreateEditModalScreen from "../../components/CreateEditModalScreen";
import ConfirmModal from "../../components/modals/ConfirmModal";

const CustomButtonComponent = (props) => {
  const { data } = props;

  const handleEdit = () => {
    if (props.onEdit) {
      props.onEdit(data);
    }
  };

  const handleDelete = () => {
    if (props.onDelete) {
      props.onDelete(data);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        gap: "14px",
        alignItems: "center",
        paddingTop: "6px",
      }}
    >
      <ButtonComponent className="material-icons download" onClick={handleEdit}>
        edit
      </ButtonComponent>
      <ButtonComponent className="material-icons " onClick={handleDelete}>
        delete
      </ButtonComponent>
    </div>
  );
};

const initialFormData = { id: "", master: "", slave: "" };

export default function SymbolMappingScreen() {
  const { t, c } = useContext(TranslatorContext);
  const { isLoading, error, data } = useSymbolMappingQuery();
  const updateMutation = useUpdateSymbolMapping();
  const createMutation = useCreateSymbolMapping();
  const {
    mutate: deleteMapping,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
  } = useDeleteSymbolMapping();

  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const IdCellRenderer = ({ node }) => {
    return node.rowIndex + 1;
  };

  const colDefs = useMemo(
    () => [
      {
        headerName: "No",
        cellRenderer: IdCellRenderer,
        flex: 1,
      },
      { field: "master", headerName: "Symbol", flex: 1 },
      { field: "slave", headerName: "LP Symbol ", flex: 1 },
      {
        field: "",
        headerName: "Actions",
        cellRenderer: CustomButtonComponent,
        cellRendererParams: {
          onEdit: (data) => handleShowEdit(data),
          onDelete: (data) => handleShowDelete(data),
        },
        flex: 1,
      },
    ],
    []
  );

  const rowData = useMemo(() => {
    const mapping = data?.data?.mapping || [];

    return mapping?.sort((a, b) => a.id - b.id);
  }, [data]);

  const handleClose = () => {
    setShow(false);
    setFormData(initialFormData);
    setIsEditMode(false);
  };

  const handleShowEdit = (data) => {
    const { master, slave, id } = data;

    setFormData((prevData) => ({
      ...prevData,
      id: id || prevData.id,
      slave: slave || prevData.slave,
      master: master || prevData.master,
    }));
    setIsEditMode(true);
    setShow(true);
  };

  const handleShowCreate = () => {
    setFormData(initialFormData);
    setIsEditMode(false);
    setShow(true);
  };

  const handleShowDelete = (data) => {
    const { master, slave, id } = data;

    setFormData((prevData) => ({
      ...prevData,
      id: id || prevData.id,
      slave: slave || prevData.slave,
      master: master || prevData.master,
    }));
    setShowConfirmModal(!showConfirmModal);
  };

  const onCreateForm = (e) => {
    e.preventDefault();
    const data = {
      slave: formData.slave,
      master: formData.master,
    };
    createMutation.mutate(data);
    setFormData(initialFormData);
    setShow(false);
  };

  const onEditFormSubmit = (e) => {
    e.preventDefault();
    updateMutation.mutate(formData);
    setFormData(initialFormData);
    setShow(false);
    setIsEditMode(false);
  };

  const handleConfirmDelete = (e) => {
    deleteMapping(formData);
    setShowConfirmModal(false);
  };

  // if (isLoading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-breadcrumb">
              <h3 className="mc-breadcrumb-title">{t("Symbol")}</h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item">
                  <Link to="/dashboard" className="mc-breadcrumb-link">
                    {t("home")}
                  </Link>
                </li>
                <li className="mc-breadcrumb-item">
                  <Link to="/symbol-mapping" className="mc-breadcrumb-link">
                    {t("Symbol")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Col>
        {/* {floats.map((float, index) => (
          <Col key={index} md={6} lg={4}>
            <FloatCardComponent
              variant={float.variant}
              digit={c(float.digit)}
              title={t(float.title)}
              icon={float.icon}
            />
          </Col>
        ))} */}
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-card-header">
              <h4
                className="mc-card-title"
                style={{ marginRight: "auto" }}
              ></h4>

              <div>
                <div onClick={handleShowCreate} style={{ cursor: "pointer" }}>
                  <div
                    style={{
                      display: "inline-flex",
                      flexDirection: "row",
                      marginLeft: "auto",
                    }}
                  >
                    <i className="material-icons">add</i> &nbsp; Create Symbol
                  </div>
                </div>
              </div>

              <Dropdown bsPrefix="mc-dropdown">
                <Dropdown.Toggle bsPrefix="mc-dropdown-toggle"></Dropdown.Toggle>
                <Dropdown.Menu align="end" className="mc-dropdown-paper">
                  <button type="button" className="mc-dropdown-menu">
                    <i className="material-icons">edit</i>
                    <span>{t("edit")}</span>
                  </button>
                  <button type="button" className="mc-dropdown-menu">
                    <i className="material-icons">delete</i>
                    <span>{t("delete")}</span>
                  </button>
                  <button type="button" className="mc-dropdown-menu">
                    <i className="material-icons">download</i>
                    <span>{t("download")}</span>
                  </button>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* <Row xs={1} sm={2} lg={4}>
              <Col>
                <LabelFieldComponent
                  type="search"
                  label={t("search_by")}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                  placeholder={t("id") + " / " + t("name")}
                />
              </Col>

              <Col>
                <LabelFieldComponent
                  type="search"
                  label={t("search_by")}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                  placeholder={t("email")}
                />
              </Col>

              <Col>
                <LabelFieldComponent
                  label={t("status_by")}
                  option={["active", "inactive", "pending"]}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                />
              </Col>
              <Col>
                <LabelFieldComponent
                  label={t("show_by")}
                  option={["12 row", "24 row", "36 row"]}
                  labelDir="label-col"
                  fieldSize="mb-4 w-100 h-md"
                />
              </Col>
            </Row> */}
            <div className="ag-theme-quartz" style={{ height: 500 }}>
              <AgGridReact
                rowData={rowData}
                columnDefs={colDefs}
                pagination={false}
                animateRows={true}
                overlayNoRowsTemplate={
                  isLoading ? "Loading symbols..." : "No symbols found"
                }
              />
            </div>
            {/* <PaginationComponent first={1} last={traffics?.tbody?.length} /> */}
          </div>
        </Col>
      </Row>
      <CustomModal show={show} handleClose={handleClose}>
        <CreateEditModalScreen
          setFormData={setFormData}
          formData={formData}
          handleClose={handleClose}
          isEditMode={isEditMode}
          onClick={isEditMode ? onEditFormSubmit : onCreateForm}
          isLoading={createMutation.isLoading}
          title="Alert"
        />
      </CustomModal>

      <ConfirmModal
        show={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        onConfirmClick={handleConfirmDelete}
        title="Confirm Delete"
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        loading={deleteIsLoading}
      >
        <p>
          Are you sure you want to delete this symbol? This action cannot be
          undone.
        </p>
      </ConfirmModal>
    </PageLayout>
  );
}
