// Functions

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../services/api";
import { useNavigate } from "react-router-dom";

const apiTimeInterval = 5000;

//************Login-start*********************//

const login = async (data) => {
  const response = await api.post("/login", data);
  return response.data;
};
//************Login-end*********************//

//************Dashboard-start*********************//
export const getStateList = async () => {
  const response = await api.get("/state");
  return response.data;
};
//************Dashboard-end*********************//

//************Dashboard-start*********************//
export const getAlertList = async () => {
  const response = await api.get("/alts");
  return response.data;
};
//************Dashboard-end*********************//

//************Mapping-start*********************//

const getSymbolMapping = async () => {
  const response = await api.get("/mapping");
  return response.data;
};

const updateSymbolMapping = async (data) => {
  const response = await api.put("/mapping", data);
  return response.data;
};

const createSymbolMapping = async (data) => {
  const response = await api.post("/mapping", data);
  return response.data;
};

const deleteSymbolMapping = async (data) => {
  const response = await api.delete(`/mapping?id=${data.id}`);
  return response.data;
};
//************Mapping-end*********************//

//************Alerts-start*********************//
const getAlertsConfig = async () => {
  const response = await api.get("/alerts");
  return response.data;
};

const createAlertConfig = async (data) => {
  const response = await api.post("/alerts", data);
  return response.data;
};
const updateAlertConfig = async (data) => {
  const response = await api.put("/alerts", data);
  return response.data;
};

const deleteAlertConfig = async (data) => {
  const response = await api.delete(`/alerts?id=${data.id}`);
  return response.data;
};
//************Alerts-end*********************//

// Actions
export const useLogin = () => {
  return useMutation({
    mutationFn: login,
  });
};

export const useSymbolMappingQuery = () => {
  return useQuery({
    queryKey: ["SymbolMapping"],
    queryFn: getSymbolMapping,
    // refetchInterval: apiTimeInterval ,
  });
};

export const useCreateSymbolMapping = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createSymbolMapping,
    onSuccess: (data) => {
      console.log("Creation successful:");
      queryClient.invalidateQueries(["SymbolMapping"]);
    },
    onError: (error) => {
      console.error("Creation failed:", error);
    },
  });
};

export const useUpdateSymbolMapping = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateSymbolMapping,
    onSuccess: (data) => {
      console.log("Update successful:");
      queryClient.invalidateQueries(["SymbolMapping"]);
    },
    onError: (error) => {
      console.error("Update failed:", error);
    },
  });
};

export const useDeleteSymbolMapping = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteSymbolMapping,
    onSuccess: (data) => {
      console.log("Deletion successful:");
      queryClient.invalidateQueries(["SymbolMapping"]);
    },
    onError: (error) => {
      console.error("Deletion failed:", error);
    },
  });
};

//************Alerts-start*********************//

export const useAlertConfigQuery = () => {
  return useQuery({
    queryKey: ["AlertConfig"],
    queryFn: getAlertsConfig,
    // refetchInterval: apiTimeInterval,
  });
};

export const useCreateAlertConfig = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createAlertConfig,
    onSuccess: (data) => {
      console.log("Creation successful:");
      queryClient.invalidateQueries(["AlertConfig"]);
    },
    onError: (error) => {
      console.error("Creation failed:", error);
    },
  });
};

export const useUpdateAlertConfig = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateAlertConfig,
    onSuccess: (data) => {
      console.log("Update successful:");
      queryClient.invalidateQueries(["AlertConfig"]);
    },
    onError: (error) => {
      console.error("Update failed:", error);
    },
  });
};

export const useDeleteAlertConfig = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteAlertConfig,
    onSuccess: (data) => {
      console.log("Deletion successful:");
      queryClient.invalidateQueries(["SymbolMapping"]);
    },
    onError: (error) => {
      console.error("Deletion failed:", error);
    },
  });
};
